/* eslint-disable */
import bootstrap from 'bootstrap';
import AOS from 'aos';
import Splide from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';
import 'masonry-layout';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgHash from 'lightgallery/plugins/hash';
import lgShare from 'lightgallery/plugins/share';

// Font Awesome Config
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPrint, faChevronLeft, faChevronRight, faChevronDown,  faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faArrowRight, faArrowLeft, faPhone, faTags, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faStore, faComments, faEnvelopeOpen, faQuoteRight, faQuoteLeft, faFilter, faPencilAlt, faEdit, faCartPlus, faTruck, faMobileAlt, faLongArrowRight, faLongArrowLeft, faCalendarAlt, faExpandWide, faCompressWide, faArrowFromRight, faMapMarker, faMap, faEnvelope, faInfoCircle, faGraduationCap, faHandsHelping, faUsers, faThumbsUp, faBagsShopping, faCar, faPhoneAlt, faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { faUser, faShoppingCart, faLocationArrow, faExclamationCircle, faCheck, faStar, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faUfoBeam } from '@fortawesome/pro-light-svg-icons';
import { faFacebookSquare, faInstagram, faTwitterSquare, faFacebook, faTwitter, faGoogle, faYelp, faMicrosoft, faLinkedin, faPinterest, faYoutube, faVimeo, faSnapchat, faTiktok, faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

// Icons with multiple styles
import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';

//add icons to lib
library.add(faEnvelope, faMap, faMapMarker, faExclamationCircle, faLocationArrow, faChevronRight, faChevronLeft, faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faStar, faArrowRight, faArrowLeft, faPhone, faUser, faTags, faPrint, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faShoppingCart, faStore, faComments, faFacebookSquare, faTwitterSquare, faFacebook, faTwitter, faInstagram, faGoogle, faYelp, faBuilding, faEnvelopeOpen, faMicrosoft, faLinkedin, faPinterest, faYoutube, faVimeo, faSnapchat, faQuoteRight, faFilter, faPencilAlt, faEdit, faCartPlus, faExternalLinkAlt, faCheck, faTruck, faMobileAlt, faLongArrowRight, faCalendarAlt, faLongArrowLeft, faQuoteLeft, faExpandWide, faCompressWide, faArrowFromRight, faUfoBeam, faTiktok, faInfoCircle, faGraduationCap, faHandsHelping, faUsers, faThumbsUp, faBagsShopping, faCar, faPhoneAlt, faSearch, faBadgeCheck, faApple, faGooglePlay, faChevronDown);


dom.watch();

export default {
    init() {
        // JavaScript to be fired on all pages
        // init aos
        AOS.init({
            disable: 'mobile',
            duration: 750,
            offset: 200,
            once: true,
        });
        setTimeout(function () {
            AOS.refresh();
        }, 500);

        $('#MuteButton').on('click', function () {
            console.log('button clicked');
            var button = document.getElementById("MuteButton")
            var video = document.getElementById("bgVideo")

            if (video.muted) {
                video.muted = false;
            } else {
                video.muted = true;
            }
            console.log(video.muted);
            button.classList.toggle('muted');
        });
        
        //add arrow to btn
        if($('.btn-arrow')[0]) {
            $('.btn-arrow').append('<i class="far fa-arrow-right ms-1"></i>');
        }
        
        // Scroll to tabContent on mobile when nav item is clicked
        $('.tabs .nav-link').on('click', function (e) {
            e.preventDefault();
            console.log('clicked');
            if ($(window).width() < 991) {
                var targetOffset = $('#tabContent').offset().top - 175;
                $('html, body').animate({ scrollTop: targetOffset }, 600);
            }
            // Show the tab explicitly
            $(this).tab('show');
        });

        // Trigger the tab switch when the circle-icon is clicked
        $('.tabs .circle-icon').on('click', function (e) {
            e.preventDefault(); // Prevent any default action if there's an anchor
            $(this).siblings('.nav-link').trigger('click');
        });

        //filter-navbar-categories button mobile functions
        setTimeout(() => {
        $('.shop .filter-navbar-categories button').on('click', function() {
            $('.shop .filter-navbar-categories button').removeClass('active');
            $(this).addClass('active'); 
            var selected_cat = $(this).text();
            $('.category-selection').text(': '+selected_cat);
        });
        }, 2000 );
        
        
        // Function to check and apply logic for viewport width under 992px
        function applyNavbarBehavior() {
            if (window.innerWidth < 992) {
                // Initially hide .show-categories
                $('.show-categories').hide();
                
                // Show .show-categories after scrolling 150px
                $(window).on('scroll.navBehavior', function() {
                    if ($(this).scrollTop() > 150) {
                        $('.show-categories').fadeIn(); // Show button
                    } else {
                        $('.show-categories').fadeOut(); // Hide button
                    }
                });
    
                // Toggle the position-fixed class on nav.headless-filter-navbar
                $('.show-categories').on('click.navBehavior', function() {
                    const navbar = $('nav.headless-filter-navbar');
                    navbar.toggleClass('position-fixed');
                    
                    // Scroll listener to remove position-fixed if back at the top
                    if ($(window).scrollTop() <= 150) {
                        navbar.removeClass('position-fixed');
                    }
                });
    
                // Ensure nav.headless-filter-navbar resets if user clicks a category and scrolls back to the top
                $(window).on('scroll.navBehavior', function() {
                    if ($(this).scrollTop() <= 150) {
                        $('nav.headless-filter-navbar').removeClass('position-fixed');
                    }
                });
            } else {
                // Unbind scroll and click events when the viewport is larger than 992px
                $(window).off('scroll.navBehavior');
                $('.show-categories').off('click.navBehavior');
                $('.show-categories').hide(); // Ensure .show-categories stays hidden
                $('nav.headless-filter-navbar').removeClass('position-fixed'); // Reset navbar state
            }
        }
    
        // Apply logic on page load and resize
        applyNavbarBehavior();
        $(window).on('resize', applyNavbarBehavior);
        
        
        
        //ada fix on plus button
        setTimeout(function() {
            $('.headless-cart-button').each(function() {
                // Check if the button is empty or does not already contain the visually hidden span
                if ($(this).text().trim() === '' && $(this).find('.visually-hidden').length === 0) {
                    // Add the visually hidden span
                    $(this).append('<span class="visually-hidden">Add to cart</span>');
                }
                // Add aria-label for accessibility
                $(this).attr('aria-label', 'Add to cart');
            });
        }, 2000); // 2000 milliseconds = 2 seconds
        
        //add arrow to .menu-item-has-children
        if($('.dropdown-menu .menu-item-has-children')[0]) {
            $('.dropdown-menu > .menu-item-has-children > a').append('<i class="far fa-arrow-right ms-1"></i>');
        }
        
        // Event listener for the #msoLocationDropdown change event
        $('#msoLocationDropdown').change(function() {
            console.log('changed');
            // Get the selected index of #msoLocationDropdown
            var selectedIndex = $(this).prop('selectedIndex');
            
            // Set the same index on #location-selector
            $('#location-selector').prop('selectedIndex', selectedIndex);
        });


        // add particle-box to all button
        $('a.btn').each(function() {
            var $btn = $(this);
            if (!$btn.closest('.particle-box').length) {
                // Wrap the button in a new div with the required classes
                $btn.wrap('<div class="particle-box d-inline-block"></div>');
            }
        });
        
        // Select the .particle-box element
        var boxElement = $('.particle-box');
        
        // If the .particle-box element exists, proceed with adding the event listeners
        if (boxElement.length > 0) {
            boxElement.on('mouseenter', function () {
            // Check if #particles-js already exists to prevent duplicate insertion
            if ($(this).find('#particles-js').length === 0) {
                // Prepend #particles-js div inside the .particle-box
                $(this).prepend('<div id="particles-js"></div>');
                
                // Initialize particles.js for the dynamically added #particles-js div
                particlesJS('particles-js', {
                "particles": {
                    "number": {
                    "value": 400,
                    "density": {
                        "enable": true,
                        "value_area": 500
                    }
                    },
                    "color": {
                    "value": "#50bddb"
                    },
                    "shape": {
                    "type": "circle",
                    "stroke": {
                        "width": 0,
                        "color": "#50bddb"
                    },
                    "polygon": {
                        "nb_sides": 5
                    }
                    },
                    "opacity": {
                    "value": 0.8,
                    "random": true,
                    "anim": {
                        "enable": true,
                        "speed": 3,
                        "opacity_min": 0.2,
                        "sync": false
                    }
                    },
                    "size": {
                    "value": 4,
                    "random": true,
                    "anim": {
                        "enable": true,
                        "speed": 15,
                        "size_min": 0.1,
                        "sync": false
                    }
                    },
                    "line_linked": {
                    "enable": true,
                    "distance": 80,
                    "color": "#50bddb",
                    "opacity": 1,
                    "width": 1.5
                    },
                    "move": {
                    "enable": true,
                    "speed": 30,
                    "direction": "none",
                    "random": true,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                    "attract": {
                        "enable": false,
                        "rotateX": 1200,
                        "rotateY": 2400
                    }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "repulse"
                    },
                    "onclick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "resize": true
                    },
                    "modes": {
                    "grab": {
                        "distance": 300,
                        "line_linked": {
                        "opacity": 1
                        }
                    },
                    "bubble": {
                        "distance": 300,
                        "size": 20,
                        "duration": 2,
                        "opacity": 0.8,
                        "speed": 3
                    },
                    "repulse": {
                        "distance": 150,
                        "duration": 0.4
                    },
                    "push": {
                        "particles_nb": 6
                    },
                    "remove": {
                        "particles_nb": 2
                    }
                    }
                },
                "retina_detect": true
                });
            }
            });
        
            boxElement.on('mouseleave', function () {
            // Remove the #particles-js div and its canvas when the mouse leaves the element
            $(this).find('#particles-js').remove();
            });
        }
        

        /****
        *************
        *************
          Nav Functions
        *************
        *************
        *****/
        // off canvas (not currently used)
        $('#desktopToggler').click(function () {
            $('.off-canvas-transform-menu').addClass('nav-flex');
            $('#siteVisibleArea').removeClass('site-visible-area-reverse');
            $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse');
            $('#siteVisibleArea').addClass('site-visible-area');
            $('#siteVisibleAreaInner').addClass('site-visible-area-inner');
            $('#canvasX').delay(500).fadeIn();
            $('#offCanvasMenu').delay(500).hide().animate({
                left: 'auto',
                opacity: 'show',
            }, 1000);
        });
        var mobileClicked = 0;
        $('#mobileToggler').click(function () {
            if (mobileClicked == 0) {
                $('#siteVisibleArea').removeClass('site-visible-area-reverse-mobile');
                $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse-mobile');
                $('#siteVisibleArea').addClass('site-visible-area-mobile');
                $('#siteVisibleAreaInner').addClass('site-visible-area-inner-mobile');
                $('#offCanvasMobile').delay(500).hide().animate({
                    left: 'auto',
                    opacity: 'show',
                }, 1000);
                mobileClicked = 1;
            } else if (mobileClicked == 1) {
                $('#siteVisibleArea').addClass('site-visible-area-reverse-mobile');
                $('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse-mobile');
                $('#siteVisibleArea').removeClass('site-visible-area-mobile');
                $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-mobile');
                $('#offCanvasMenu').hide();
                mobileClicked = 0;
            }
        });
        $('#canvasX').click(function () {
            $('.off-canvas-transform-menu').removeClass('nav-flex');
            $('#siteVisibleArea').addClass('site-visible-area-reverse');
            $('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse');
            $('#siteVisibleArea').removeClass('site-visible-area');
            $('#siteVisibleAreaInner').removeClass('site-visible-area-inner');
            $('#canvasX').hide();
            $('#offCanvasMenu').hide();
        });
        // dropdown on hover

        // Transparent nav scrolling function
        //caches a jQuery object containing the header element
        var transNav = $('#topLevelNav');
        var transNavHeight = transNav.outerHeight();
        var pageHeader = $('.page-header');
        var pageHeaderPadding = transNavHeight + 'px';
        // if trans nav, set initial page header top padding
        if ($('.trans-nav')[0]) {
            pageHeader.css('padding-top', pageHeaderPadding);
        }
        $(window).on('load scroll', function () {
            var transScroll = $(window).scrollTop();
            if (transScroll > 0.00) {
                transNav.removeClass('trans-top');
                transNav.addClass('trans-scrolling');
            } else {
                transNav.addClass('trans-top');
                transNav.removeClass('trans-scrolling');
            }
        });
        // Shrink on scroll nav function
        //caches a jQuery object containing the header element
        var shrinkNav = $('#topLevelNav');
        var shrinkNavbar = $('#site-navigation');
        if (shrinkNav.hasClass('shrink-on-scroll')) {
            $(window).on('load scroll', function () {
                var shrinkScroll = $(window).scrollTop();
                if (shrinkScroll > 0.00) {
                    shrinkNav.addClass('nav-shrink');
                    shrinkNavbar.removeClass('py-3');
                    shrinkNavbar.addClass('py-2');
                    return false;
                } else {
                    shrinkNav.removeClass('nav-shrink');
                    shrinkNavbar.addClass('py-3');
                    shrinkNavbar.removeClass('py-2');
                    return false;
                }
            });
        }
        // Hide topbar on scroll
        //caches a jQuery object containing the header element
        var hideTopbar = $('.topbar-hide');
        var topbarHidden = false;
        if (hideTopbar) {
            if (topbarHidden != true) {
                $(window).scroll(function () {
                    var topbarOffset = $(window).scrollTop();
                    if (topbarOffset > 50.00) {
                        hideTopbar.css('display', 'none');
                        topbarHidden = true;
                        return false;
                    }
                    else if (topbarOffset < 10.00) {
                        hideTopbar.css('display', 'block');
                        return false;
                    }
                });
            }
        }
        /****
        *************
        *************
          Add ADA Compliance to h1, h2, h3 etc div tags
        *************
        *************
        *****/
        $('.h1').each(function () {
            var h1Title = $(this);
            h1Title.attr('role', 'heading');
            h1Title.attr('aria-level', '1');
        });
        $('.h2').each(function () {
            var h2Title = $(this);
            h2Title.attr('role', 'heading');
            h2Title.attr('aria-level', '1');
        });
        $('.h3').each(function () {
            var h3Title = $(this);
            h3Title.attr('role', 'heading');
            h3Title.attr('aria-level', '1');
        });
        $('.h4').each(function () {
            var h4Title = $(this);
            h4Title.attr('role', 'heading');
            h4Title.attr('aria-level', '1');
        });
        $('.h5').each(function () {
            var h5Title = $(this);
            h5Title.attr('role', 'heading');
            h5Title.attr('aria-level', '1');
        });
        /****
        *************
        *************
          Sliders
        *************
        *************
        *****/

        /*
         ** Logo Banner Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var logoSliders = document.getElementsByClassName('rpb-logo-slider');
        for (var logoCount = 0; logoCount < logoSliders.length; logoCount++) {
            // mount a new slider with the Intersection observer
            var logoSplide = new Splide(logoSliders[logoCount]).mount({ Intersection });
            logoSplide.on('intersection:in', function (entry) {
                $(entry.target).find('.logo-banner-img').each(function () {
                    var logoAttr = $(this).attr('data-src');
                    $(this).attr('src', logoAttr);
                });
            });
        }

        /*
         ** Hero Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        // var heroSliders = document.getElementsByClassName('rpb-hero-slider');
        // for (var heroCount = 0; heroCount < heroSliders.length; heroCount++) {
        //     new Splide(heroSliders[heroCount]).mount();
        // }
        // Loop through each instance of a slider and initialize based on the matching class
        var heroSliders = document.getElementsByClassName('rpb-hero-slider');
        for (var heroSliderCount = 0; heroSliderCount < heroSliders.length; heroSliderCount++) {
            // mount a new slider with the Intersection observer
            var heroSlider = new Splide(heroSliders[heroSliderCount]).mount({ Intersection });
            // on intersection, set the data-src of the slider img to the src
            heroSlider.on('intersection:in', function (entry) {
                $(entry.target).find('.lazy').each(function () {
                    if (entry.isIntersecting) {
                        var imgAttr = $(this).attr('data-src');
                        $(this).attr('src', imgAttr);
                        var bgImage = $(this).attr('data-bgimage');
                        if (bgImage) {
                            this.style.backgroundImage = bgImage;
                        }
                        var bgSize = $(this).attr('data-bgsize');
                        if (bgSize) {
                            this.style.backgroundSize = bgSize;
                        }
                        var bgPosition = $(this).attr('data-bgposition');
                        if (bgPosition) {
                            this.style.backgroundPosition = bgPosition;
                        }
                        var bgRepeat = $(this).attr('data-bgrepeat');
                        if (bgRepeat) {
                            this.style.backgroundRepeat = bgRepeat;
                        }
                    }
                });
            });
        }

        /*
         ** Bootstrap Cards Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var cardSliders = document.getElementsByClassName('bootstrap-cards-slider');
        for (var cardSliderCount = 0; cardSliderCount < cardSliders.length; cardSliderCount++) {
            // mount a new slider with the Intersection observer
            var cardSlider = new Splide(cardSliders[cardSliderCount]).mount({ Intersection });
            // on intersection, set the data-src of the slider img to the src
            cardSlider.on('intersection:in', function (entry) {
                $(entry.target).find('.lazy').each(function () {
                    if (entry.isIntersecting) {
                        var imgAttr = $(this).attr('data-src');
                        $(this).attr('src', imgAttr);
                        var bgImage = $(this).attr('data-bgimage');
                        if (bgImage) {
                            this.style.backgroundImage = bgImage;
                        }
                        var bgSize = $(this).attr('data-bgsize');
                        if (bgSize) {
                            this.style.backgroundSize = bgSize;
                        }
                        var bgPosition = $(this).attr('data-bgposition');
                        if (bgPosition) {
                            this.style.backgroundPosition = bgPosition;
                        }
                        var bgRepeat = $(this).attr('data-bgrepeat');
                        if (bgRepeat) {
                            this.style.backgroundRepeat = bgRepeat;
                        }
                    }
                });
            });
        }


        /*
         ** Bootstrap Cards Fade Slider
         */
        var splideFadeSlider = document.getElementsByClassName('range-fade-slider');
        for (var splideFadeSliderCount = 0; splideFadeSliderCount < splideFadeSlider.length; splideFadeSliderCount++) {
            new Splide(splideFadeSlider[splideFadeSliderCount], {
                type: "fade",
                rewind: true,
            }).mount();
        }

        /*
         ** Column Content Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var contentSliders = document.getElementsByClassName('range-content-slider');
        for (var contentSliderCount = 0; contentSliderCount < contentSliders.length; contentSliderCount++) {
            // mount a new slider with the Intersection observer
            var contentSlider = new Splide(contentSliders[contentSliderCount]).mount({ Intersection });
            // on intersection, set the data-src of the slider img to the src
            contentSlider.on('intersection:in', function (entry) {
                $(entry.target).find('.lazy').each(function () {
                    if (entry.isIntersecting) {
                        var imgAttr = $(this).attr('data-src');
                        $(this).attr('src', imgAttr);
                        var bgImage = $(this).attr('data-bgimage');
                        if (bgImage) {
                            this.style.backgroundImage = bgImage;
                        }
                        var bgSize = $(this).attr('data-bgsize');
                        if (bgSize) {
                            this.style.backgroundSize = bgSize;
                        }
                        var bgPosition = $(this).attr('data-bgposition');
                        if (bgPosition) {
                            this.style.backgroundPosition = bgPosition;
                        }
                        var bgRepeat = $(this).attr('data-bgrepeat');
                        if (bgRepeat) {
                            this.style.backgroundRepeat = bgRepeat;
                        }
                    }
                });
            });
        }

        /*
         ** Coupon Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var couponSliders = document.getElementsByClassName('rpb-coupon-slider');
        for (var couponSlidersCount = 0; couponSlidersCount < couponSliders.length; couponSlidersCount++) {
            // mount a new slider with the Intersection observer
            var couponSlider = new Splide(couponSliders[couponSlidersCount]).mount({ Intersection });
            // on intersection, set the data-src of the slider img to the src
            couponSlider.on('intersection:in', function (entry) {
                $(entry.target).find('.lazy').each(function () {
                    if (entry.isIntersecting) {
                        var imgAttr = $(this).attr('data-src');
                        $(this).attr('src', imgAttr);
                        var bgImage = $(this).attr('data-bgimage');
                        if (bgImage) {
                            this.style.backgroundImage = bgImage;
                        }
                        var bgSize = $(this).attr('data-bgsize');
                        if (bgSize) {
                            this.style.backgroundSize = bgSize;
                        }
                        var bgPosition = $(this).attr('data-bgposition');
                        if (bgPosition) {
                            this.style.backgroundPosition = bgPosition;
                        }
                        var bgRepeat = $(this).attr('data-bgrepeat');
                        if (bgRepeat) {
                            this.style.backgroundRepeat = bgRepeat;
                        }
                    }
                });
            });
        }

        /*
        ** Image Slider
        */

        // Loop through each instance of a slider and initialize based on the matching class
        var imageSliders = document.getElementsByClassName('rpb-image-slider');
        for (var imageSliderCount = 0; imageSliderCount < imageSliders.length; imageSliderCount++) {
            // assign slider elements to variables
            var sliderID = imageSliders[imageSliderCount].id;
            var sliderElement = document.getElementById(sliderID);
            var sliderParentID = sliderElement.parentElement.id;
            var sliderParentElement = document.querySelector('#' + sliderParentID);
            var thumbnailSliderElement = sliderParentElement.querySelector('.rpb-image-slider-thumbnails');

            // slider without thumbnails
            if (!thumbnailSliderElement) {
                var imageSlider = new Splide(imageSliders[imageSliderCount], {
                    // Add your configurations for sliders without thumbnails
                }).mount({ Intersection });

                imageSlider.on('intersection:in', function (entry) {
                    $(entry.target).find('.lazy').each(function () {
                        if (entry.isIntersecting) {
                            var imgAttr = $(this).attr('data-src');
                            $(this).attr('src', imgAttr);
                            var bgImage = $(this).attr('data-bgimage');
                            if (bgImage) {
                                this.style.backgroundImage = bgImage;
                            }
                            var bgSize = $(this).attr('data-bgsize');
                            if (bgSize) {
                                this.style.backgroundSize = bgSize;
                            }
                            var bgPosition = $(this).attr('data-bgposition');
                            if (bgPosition) {
                                this.style.backgroundPosition = bgPosition;
                            }
                            var bgRepeat = $(this).attr('data-bgrepeat');
                            if (bgRepeat) {
                                this.style.backgroundRepeat = bgRepeat;
                            }
                        }
                    });
                });
            } else {
                // assign the thumbnail slider a variable
                var thumbnailSlider = new Splide(thumbnailSliderElement, {
                    rewind: true,
                    fixedWidth: 75,
                    fixedHeight: 75,
                    isNavigation: true,
                    arrows: false,
                    gap: 10,
                    pagination: false,
                    cover: true,
                    dragMinThreshold: {
                        mouse: 4,
                        touch: 10,
                    },
                    breakpoints: {
                        640: {
                            fixedWidth: 66,
                            fixedHeight: 38,
                        },
                    },
                });

                var mainSlider = new Splide(imageSliders[imageSliderCount]);

                // mount the sliders
                mainSlider.sync(thumbnailSlider);
                mainSlider.mount();
                thumbnailSlider.mount();
            }
        }

        /*
         ** Customer Reviews Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var reviewSliders = document.getElementsByClassName('customer-reviews-slider');
        for (var reviewSlidersCount = 0; reviewSlidersCount < reviewSliders.length; reviewSlidersCount++) {
            // mount a new slider with the Intersection observer
            var reviewSlider = new Splide(reviewSliders[reviewSlidersCount]).mount({ Intersection });
            // on intersection, set the data-src of the slider img to the src
            reviewSlider.on('intersection:in', function (entry) {
                // console.log( 'in', entry.target );
                $(entry.target).find('.review-img').each(function () {
                    //console.log('found' + i);
                    var reviewAttr = $(this).attr('data-src');
                    $(this).attr('src', reviewAttr);
                    var bgImage = $(this).attr('data-bgimage');
                    if (bgImage) {
                        this.style.backgroundImage = bgImage;
                    }
                    var bgSize = $(this).attr('data-bgsize');
                    if (bgSize) {
                        this.style.backgroundSize = bgSize;
                    }
                    var bgPosition = $(this).attr('data-bgposition');
                    if (bgPosition) {
                        this.style.backgroundPosition = bgPosition;
                    }
                    var bgRepeat = $(this).attr('data-bgrepeat');
                    if (bgRepeat) {
                        this.style.backgroundRepeat = bgRepeat;
                    }
                });
            });
        }

        /*
         ** Blog Feed Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var blogFeeds = document.getElementsByClassName('blog-feed-slider');
        for (var blogFeedsCount = 0; blogFeedsCount < blogFeeds.length; blogFeedsCount++) {
            new Splide(blogFeeds[blogFeedsCount]).mount();
        }

        /****
        *************
        *************
          Additional Page Builder Functions
        *************
        *************
        *****/

        // Photo Gallery Lightgallery
        var galleryLightbox = document.getElementsByClassName('gallery-lightbox');
        for (var galleryCount = 0; galleryCount < galleryLightbox.length; galleryCount++) {
            var galleryID = galleryLightbox[galleryCount].id;
            var galleryElement = document.getElementById(galleryID);
            var gallerySelector = $('.photo-gallery-photo');
            var galleryAlt = $(gallerySelector).attr('data-alt');
            lightGallery(galleryElement, {
                plugins: [lgZoom, lgThumbnail, lgHash, lgShare],
                thumbnail: false,
                selector: '.photo-gallery-photo',
                alt: galleryAlt,
            });
            console.log(galleryAlt);
        }

        /****
        *************
        *************
          WooCommerce
        *************
        *************
        *****/
        // show mobile filter on button click
        $('#shopFilter').click(function () {
            $('.basebuild-shop-filter-in').slideToggle();
        });
        // review form on button click
        $('#addRevewButton').click(function () {
            $('#commentform').slideToggle();
        });
        if (!String.prototype.getDecimals) {
            String.prototype.getDecimals = function () {
                var num = this,
                    match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
                if (!match) {
                    return 0;
                }
                return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
            }
        }
        // Quantity "plus" and "minus" buttons
        $(document.body).on('click', '.plus, .minus', function () {
            var $qty = $(this).closest('.quantity').find('.qty'),
                currentVal = parseFloat($qty.val()),
                max = parseFloat($qty.attr('max')),
                min = parseFloat($qty.attr('min')),
                step = $qty.attr('step');
            // Format values
            if (!currentVal || currentVal === '' || currentVal === 'NaN') currentVal = 0;
            if (max === '' || max === 'NaN') max = '';
            if (min === '' || min === 'NaN') min = 0;
            if (step === 'any' || step === '' || step === undefined || parseFloat(step) === 'NaN') step = 1;
            // Change the value
            if ($(this).is('.plus')) {
                if (max && (currentVal >= max)) {
                    $qty.val(max);
                } else {
                    $qty.val((currentVal + parseFloat(step)).toFixed(step.getDecimals()));
                }
            } else {
                if (min && (currentVal <= min)) {
                    $qty.val(min);
                } else if (currentVal > 0) {
                    $qty.val((currentVal - parseFloat(step)).toFixed(step.getDecimals()));
                }
            }
            // Trigger change event
            $qty.trigger('change');
        });

        /****
            *************
            *************
              MSO Functionality
            *************
            *************
            *****/
        var collapseElementList = [].slice.call(document.getElementsByClassName('.mso-collapse'));
        var collapseList = collapseElementList.map(function (collapseEl) {
            return new bootstrap.Collapse(collapseEl);
        });
        $('.collapse').on("show.bs.collapse", function () {
            $(this).closest('.mso-sidebar-button').addClass('active');
            $(this).closest('.mso-sidebar-button').addClass('py-3');
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').hide();
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').hide();
        });
        $('.collapse').on("hide.bs.collapse", function () {
            $(this).closest('.mso-sidebar-button').removeClass('active');
            $(this).closest('.mso-sidebar-button').removeClass('py-3');
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').show();
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').show();
        });
        var collapseElementList = [].slice.call(document.getElementsByClassName('.mso-collapse'));
        var collapseList = collapseElementList.map(function (collapseEl) {
            return new bootstrap.Collapse(collapseEl);
        });
        $('.collapse').on('show.bs.collapse', function () {
            $(this).closest('.mso-sidebar-button').addClass('active');
            $(this).closest('.mso-sidebar-button').addClass('py-3');
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').hide();
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').hide();
        });
        $('.collapse').on('hide.bs.collapse', function () {
            $(this).closest('.mso-sidebar-button').removeClass('active');
            $(this).closest('.mso-sidebar-button').removeClass('py-3');
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').show();
            $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').show();
        });
        // Fullscreen Map Expand
        $('#msoFullscreen').click(function () {
            $('#msoMap').toggleClass('fullscreen');
            $('.msofullscreen').toggleClass('d-none');
            $('.msocompress').toggleClass('d-block');
            $('.store-locator-sidebar').toggleClass('rounded-0');
            $('.store-locator-fullscreen-in').toggleClass('rounded-bottom-0');
            $('body').toggleClass('overflow-hidden');
        });

        // Show filters (desktop)
        $('#showFilters').click(function () {
            $('#msoFilterPanel').css('width', '200%');
            $('#msoFilterPanel').css('opacity', '1');
            $('#msoFilterPanel').css('visibility', 'visible');
            $('.mso-filter-back').css('visibility', 'visible');
        });
        // Hide filters (desktop)
        $('#msoFilterBack').click(function () {
            $('#msoFilterPanel').css('width', '0');
            $('#msoFilterPanel').css('opacity', '0');
            $('#msoFilterPanel').css('visibility', 'hidden');
            $('.mso-filter-back').css('visibility', 'hidden');
        });

        // Show filters (mobile)
        $('#showFiltersMobile').click(function () {
            $('#msoFilterPanel').css('opacity', '1');
            $('#msoFilterPanel').css('visibility', 'visible');
            $('.mso-filter-back').css('visibility', 'visible');
        });
        // Hide filters (mobile)
        $('#msoFilterBackMobile').click(function () {
            $('#msoFilterPanel').css('width', '0');
            $('#msoFilterPanel').css('opacity', '0');
            $('#msoFilterPanel').css('visibility', 'hidden');
            $('.mso-filter-back').css('visibility', 'hidden');
        });

        // Apply filters (hide filter)
        // Desktop
        $('#submitMsoFilters').click(function () {
            $('#msoFilterPanel').css('width', '0');
            $('#msoFilterPanel').css('opacity', '0');
            $('#msoFilterPanel').css('visibility', 'hidden');
            $('.mso-filter-back').css('visibility', 'hidden');
        });
        $('#submitMsoFiltersMobile').click(function () {
            $('#msoFilterPanel').css('opacity', '0');
            $('#msoFilterPanel').css('visibility', 'hidden');
            $('.mso-filter-back').css('visibility', 'hidden');
        });
        /*
        ** Location Popup Slider
        */
        var locationPopups = document.getElementsByClassName('location-popup-slider');
        for (var locationPopupsCount = 0; locationPopupsCount < locationPopups.length; locationPopupsCount++) {
            new Splide(locationPopups[locationPopupsCount]).mount();
        }
        /*
        ** Dutchie deep linkinking from location modal
        */
        var locationModal = document.getElementById('openLocationsModal');
        if (locationModal) {
            locationModal.addEventListener('shown.bs.modal', function (event) {
                let buttonClicked = event.relatedTarget;
                let productType = jQuery(buttonClicked).attr('data-category');
                let linkAppend = '?dtche%5Bcategory%5D=' + productType + '#menu';
                jQuery('#openLocationsModal .location-popup-link').each(function () {
                    this.href += linkAppend;
                });
            });
        }
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
